<template>
  <div>
    <el-card class="mb15" shadow="hover">
      <el-row>
        <el-input
          :placeholder="$t('search')"
          clearable
          v-model="filter.search"
          @keyup.enter.native="get_list"
        ></el-input>
      </el-row>
      <el-row class="mt20" type="flex" align="middle">
        <el-button @click="get_list" type="primary" class="search">{{
          $t("search_zh")
        }}</el-button>
        <el-button @click="reset" type="info">{{
          $t("btnTip.reset")
        }}</el-button>
      </el-row>
    </el-card>
    <el-card shadow="hover">
      <div class="flexBC mb20">
        <div></div>
        <div>
          <el-button type="primary" @click="outExe">{{ $t("outExe") }}</el-button>
          <el-button
          type="primary"
          @click="outExeAll('orderList')"
          >전체출력</el-button>
        </div>
      </div>
      <my-table
        :columns="columns"
        :data="list_data.list"
        :showIndex="false"
        :showSelection="false"
        :cellStyle="{ padding: '6px 0' }"
        :headerCellStyle="{
          background: 'rgba(51, 55, 68, 1)',
          color: '#fff',
        }"
        @emitSelection="allSelect"
        @sortChange="sort_change"
      >
      <template v-slot:user_username="slotProps">
          <div>
            {{ slotProps.callback.row.user.username }}
          </div>
        </template>
      <template v-slot:subject_name="slotProps">
          <div>
            {{ slotProps.callback.row.subject.name }}
          </div>
        </template>
      <template v-slot:status="slotProps">
          <el-tag :type="statusTag(slotProps.callback.row.status)">
            {{ status(slotProps.callback.row.status) }}
          </el-tag>
        </template>
        <template v-slot:operation="slotProps">
            <el-tooltip class="item" effect="dark" :content="$t('btnTip').check" placement="top">
             <el-button
              @click="handleClick('check',slotProps.callback.row)"
              icon="el-icon-view"
              type="success"
              size="mini"
            ></el-button>
          </el-tooltip>
          <el-button
              @click="del(slotProps.callback.row.id)"
              type="danger"
              size="mini"
              >{{ $t("btnTip").delete }}</el-button>
        </template>
      </my-table>
      <page
        :total="list_data.total"
        :page_size.sync="pageSize"
        :page.sync="page"
      />
    </el-card>
  </div>
</template>
<script>
import { mixin_list } from "@/mixins";
import page from "@/components/page";
import myTable from "@/components/table";
import { orderList, orderDelete } from "@/api/index";
export default {
  name: "score",
  mixins: [mixin_list(orderList)],
  components: {
    myTable,
    page,
  },
  data() {
    return {
      filter: {
        search: "",
      },
      columns: [
        {
          label: "ID",
          sortable: true,
          prop: "id",
          width: 80,
        },
        // {
        //   label: this.$t("order.title"),
        //   sortable: true,
        //   prop: "title",
        // },
        {
          label: this.$t("order.user_username"),
          sortable: true,
          slot:"user_username",
          prop: "user.username",
        },
        {
          label: this.$t("order.subject_name"),
          sortable: true,
          slot:"subject_name",
          prop: "subject.name",
        },
        // {
        //   label: this.$t("order.order_id"),
        //   sortable: true,
        //   prop: "order_id",
        // },
        {
          label: this.$t("order.price"),
          sortable: true,
          prop: "price",
        },
        {
          label: this.$t("order.score"),
          sortable: true,
          prop: "score",
        },
        {
          label: this.$t("order.paytime"),
          sortable: true,
          prop: "paytime",
        },
        // {
        //   label: this.$t("order.status"),
        //   sortable: true,
        //   prop: "status",
        //   slot: "status"
        // },
        {
          label: this.$t("operation"),
          prop: "",
          slot: "operation",
        },
      ],
    };
  },
  methods: {
      status(status){
          switch(status){
              case 1:
                  return this.$t('order.status1')
            case 2:
                  return this.$t('order.status2')
            case 3:
                  return this.$t('order.status3')
          }
      },
      statusTag(statusTag){
          switch(statusTag){
              case 1:
                  return 'warning'
            case 2:
                return 'success'
            case 3: 
            return 'info'
          }
      },
      async handleClick(type, data) {
      switch (type) {
        case "check":
            this.$router.push(`/order/detail?id=${data.id}`)
            break
      }
    },
    async del(id) {
      this.$confirm(this.$t('sureDelete'), this.$t('tips'), {
        confirmButtonText: this.$t('btnTips').submit,
        cancelButtonText: this.$t('btnTips').cancel,
        type: 'warning'
      }).then(async () => {
        await orderDelete(id)
        this.get_list()
      }).catch({
      })
    },
  },
};
</script>
<style lang="scss" scoped>
.text{
  width:80px;
  font-size: 16px;
}
/deep/ label{
  margin-bottom:0!important
}
</style>